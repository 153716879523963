export const DEFAULT_YEAR = 2022;
export const MIN_YEARLY_SALARY_FOR_HUVUDREGELN = 612000;

export const getUtdelningsUtrymme = (year = DEFAULT_YEAR): number => {
  switch (year) {
    case 2022: return 187550;
    default: return 0;
  }
}

export const getUtdelning = (monthlySalary: number, taxed = false, procent: number): number => {
  let res;
  const yearlySalary = 12 * monthlySalary;
  if (yearlySalary > 0 && yearlySalary < MIN_YEARLY_SALARY_FOR_HUVUDREGELN) {
    res = taxed ? getUtdelningsUtrymme(DEFAULT_YEAR) * 0.8 : getUtdelningsUtrymme(DEFAULT_YEAR);
  } else if (yearlySalary >= MIN_YEARLY_SALARY_FOR_HUVUDREGELN) {
    res = taxed ? ((yearlySalary / 2) + 2307) * 0.8 : (yearlySalary / 2) + 2307;
  } else {
    res = 0;
  }

  return res * (procent/100);
};

export const getTax = (salary: number): number => {
  if (salary >= 1701 && salary <= 1800) {
    return 126;
  }
  if (salary >= 1801 && salary <= 1900) {
    return 137;
  }
  if (salary >= 1901 && salary <= 2000) {
    return 147;
  }
  if (salary >= 2001 && salary <= 2100) {
    return 158;
  }
  if (salary >= 2101 && salary <= 2200) {
    return 160;
  }
  if (salary >= 2201 && salary <= 2300) {
    return 171;
  }
  if (salary >= 2301 && salary <= 2400) {
    return 181;
  }
  if (salary >= 2401 && salary <= 2500) {
    return 192;
  }
  if (salary >= 2501 && salary <= 2600) {
    return 202;
  }
  if (salary >= 2601 && salary <= 2700) {
    return 213;
  }
  if (salary >= 2701 && salary <= 2800) {
    return 223;
  }
  if (salary >= 2801 && salary <= 2900) {
    return 225;
  }
  if (salary >= 2901 && salary <= 3000) {
    return 236;
  }
  if (salary >= 3001 && salary <= 3100) {
    return 246;
  }
  if (salary >= 3101 && salary <= 3200) {
    return 257;
  }
  if (salary >= 3201 && salary <= 3300) {
    return 267;
  }
  if (salary >= 3301 && salary <= 3400) {
    return 278;
  }
  if (salary >= 3401 && salary <= 3500) {
    return 280;
  }
  if (salary >= 3501 && salary <= 3600) {
    return 290;
  }
  if (salary >= 3601 && salary <= 3700) {
    return 301;
  }
  if (salary >= 3701 && salary <= 3800) {
    return 311;
  }
  if (salary >= 3801 && salary <= 3900) {
    return 322;
  }
  if (salary >= 3901 && salary <= 4000) {
    return 332;
  }
  if (salary >= 4001 && salary <= 4100) {
    return 334;
  }
  if (salary >= 4101 && salary <= 4200) {
    return 344;
  }
  if (salary >= 4201 && salary <= 4300) {
    return 354;
  }
  if (salary >= 4301 && salary <= 4400) {
    return 364;
  }
  if (salary >= 4401 && salary <= 4500) {
    return 374;
  }
  if (salary >= 4501 && salary <= 4600) {
    return 384;
  }
  if (salary >= 4601 && salary <= 4700) {
    return 386;
  }
  if (salary >= 4701 && salary <= 4800) {
    return 396;
  }
  if (salary >= 4801 && salary <= 4900) {
    return 406;
  }
  if (salary >= 4901 && salary <= 5000) {
    return 416;
  }
  if (salary >= 5001 && salary <= 5100) {
    return 426;
  }
  if (salary >= 5101 && salary <= 5200) {
    return 436;
  }
  if (salary >= 5201 && salary <= 5300) {
    return 447;
  }
  if (salary >= 5301 && salary <= 5400) {
    return 448;
  }
  if (salary >= 5401 && salary <= 5500) {
    return 458;
  }
  if (salary >= 5501 && salary <= 5600) {
    return 468;
  }
  if (salary >= 5601 && salary <= 5700) {
    return 478;
  }
  if (salary >= 5701 && salary <= 5800) {
    return 488;
  }
  if (salary >= 5801 && salary <= 5900) {
    return 498;
  }
  if (salary >= 5901 && salary <= 6000) {
    return 500;
  }
  if (salary >= 6001 && salary <= 6100) {
    return 511;
  }
  if (salary >= 6101 && salary <= 6200) {
    return 530;
  }
  if (salary >= 6201 && salary <= 6300) {
    return 549;
  }
  if (salary >= 6301 && salary <= 6400) {
    return 568;
  }
  if (salary >= 6401 && salary <= 6500) {
    return 586;
  }
  if (salary >= 6501 && salary <= 6600) {
    return 605;
  }
  if (salary >= 6601 && salary <= 6700) {
    return 624;
  }
  if (salary >= 6701 && salary <= 6800) {
    return 643;
  }
  if (salary >= 6801 && salary <= 6900) {
    return 661;
  }
  if (salary >= 6901 && salary <= 7000) {
    return 680;
  }
  if (salary >= 7001 && salary <= 7100) {
    return 699;
  }
  if (salary >= 7101 && salary <= 7200) {
    return 718;
  }
  if (salary >= 7201 && salary <= 7300) {
    return 737;
  }
  if (salary >= 7301 && salary <= 7400) {
    return 755;
  }
  if (salary >= 7401 && salary <= 7500) {
    return 774;
  }
  if (salary >= 7501 && salary <= 7600) {
    return 793;
  }
  if (salary >= 7601 && salary <= 7700) {
    return 812;
  }
  if (salary >= 7701 && salary <= 7800) {
    return 831;
  }
  if (salary >= 7801 && salary <= 7900) {
    return 849;
  }
  if (salary >= 7901 && salary <= 8000) {
    return 868;
  }
  if (salary >= 8001 && salary <= 8100) {
    return 887;
  }
  if (salary >= 8101 && salary <= 8200) {
    return 906;
  }
  if (salary >= 8201 && salary <= 8300) {
    return 925;
  }
  if (salary >= 8301 && salary <= 8400) {
    return 943;
  }
  if (salary >= 8401 && salary <= 8500) {
    return 962;
  }
  if (salary >= 8501 && salary <= 8600) {
    return 981;
  }
  if (salary >= 8601 && salary <= 8700) {
    return 1000;
  }
  if (salary >= 8701 && salary <= 8800) {
    return 1019;
  }
  if (salary >= 8801 && salary <= 8900) {
    return 1037;
  }
  if (salary >= 8901 && salary <= 9000) {
    return 1056;
  }
  if (salary >= 9001 && salary <= 9100) {
    return 1075;
  }
  if (salary >= 9101 && salary <= 9200) {
    return 1094;
  }
  if (salary >= 9201 && salary <= 9300) {
    return 1113;
  }
  if (salary >= 9301 && salary <= 9400) {
    return 1131;
  }
  if (salary >= 9401 && salary <= 9500) {
    return 1150;
  }
  if (salary >= 9501 && salary <= 9600) {
    return 1169;
  }
  if (salary >= 9601 && salary <= 9700) {
    return 1188;
  }
  if (salary >= 9701 && salary <= 9800) {
    return 1207;
  }
  if (salary >= 9801 && salary <= 9900) {
    return 1225;
  }
  if (salary >= 9901 && salary <= 10000) {
    return 1244;
  }
  if (salary >= 10001 && salary <= 10100) {
    return 1263;
  }
  if (salary >= 10101 && salary <= 10200) {
    return 1282;
  }
  if (salary >= 10201 && salary <= 10300) {
    return 1301;
  }
  if (salary >= 10301 && salary <= 10400) {
    return 1319;
  }
  if (salary >= 10401 && salary <= 10500) {
    return 1338;
  }
  if (salary >= 10501 && salary <= 10600) {
    return 1357;
  }
  if (salary >= 10601 && salary <= 10700) {
    return 1376;
  }
  if (salary >= 10701 && salary <= 10800) {
    return 1395;
  }
  if (salary >= 10801 && salary <= 10900) {
    return 1413;
  }
  if (salary >= 10901 && salary <= 11000) {
    return 1432;
  }
  if (salary >= 11001 && salary <= 11100) {
    return 1451;
  }
  if (salary >= 11101 && salary <= 11200) {
    return 1470;
  }
  if (salary >= 11201 && salary <= 11300) {
    return 1489;
  }
  if (salary >= 11301 && salary <= 11400) {
    return 1508;
  }
  if (salary >= 11401 && salary <= 11500) {
    return 1528;
  }
  if (salary >= 11501 && salary <= 11600) {
    return 1547;
  }
  if (salary >= 11601 && salary <= 11700) {
    return 1566;
  }
  if (salary >= 11701 && salary <= 11800) {
    return 1585;
  }
  if (salary >= 11801 && salary <= 11900) {
    return 1604;
  }
  if (salary >= 11901 && salary <= 12000) {
    return 1623;
  }
  if (salary >= 12001 && salary <= 12100) {
    return 1642;
  }
  if (salary >= 12101 && salary <= 12200) {
    return 1661;
  }
  if (salary >= 12201 && salary <= 12300) {
    return 1680;
  }
  if (salary >= 12301 && salary <= 12400) {
    return 1699;
  }
  if (salary >= 12401 && salary <= 12500) {
    return 1718;
  }
  if (salary >= 12501 && salary <= 12600) {
    return 1738;
  }
  if (salary >= 12601 && salary <= 12700) {
    return 1757;
  }
  if (salary >= 12701 && salary <= 12800) {
    return 1776;
  }
  if (salary >= 12801 && salary <= 12900) {
    return 1795;
  }
  if (salary >= 12901 && salary <= 13000) {
    return 1814;
  }
  if (salary >= 13001 && salary <= 13100) {
    return 1839;
  }
  if (salary >= 13101 && salary <= 13200) {
    return 1865;
  }
  if (salary >= 13201 && salary <= 13300) {
    return 1892;
  }
  if (salary >= 13301 && salary <= 13400) {
    return 1919;
  }
  if (salary >= 13401 && salary <= 13500) {
    return 1946;
  }
  if (salary >= 13501 && salary <= 13600) {
    return 1972;
  }
  if (salary >= 13601 && salary <= 13700) {
    return 1999;
  }
  if (salary >= 13701 && salary <= 13800) {
    return 2026;
  }
  if (salary >= 13801 && salary <= 13900) {
    return 2052;
  }
  if (salary >= 13901 && salary <= 14000) {
    return 2079;
  }
  if (salary >= 14001 && salary <= 14100) {
    return 2105;
  }
  if (salary >= 14101 && salary <= 14200) {
    return 2130;
  }
  if (salary >= 14201 && salary <= 14300) {
    return 2156;
  }
  if (salary >= 14301 && salary <= 14400) {
    return 2182;
  }
  if (salary >= 14401 && salary <= 14500) {
    return 2207;
  }
  if (salary >= 14501 && salary <= 14600) {
    return 2233;
  }
  if (salary >= 14601 && salary <= 14700) {
    return 2258;
  }
  if (salary >= 14701 && salary <= 14800) {
    return 2284;
  }
  if (salary >= 14801 && salary <= 14900) {
    return 2310;
  }
  if (salary >= 14901 && salary <= 15000) {
    return 2335;
  }
  if (salary >= 15001 && salary <= 15100) {
    return 2361;
  }
  if (salary >= 15101 && salary <= 15200) {
    return 2386;
  }
  if (salary >= 15201 && salary <= 15300) {
    return 2412;
  }
  if (salary >= 15301 && salary <= 15400) {
    return 2438;
  }
  if (salary >= 15401 && salary <= 15500) {
    return 2463;
  }
  if (salary >= 15501 && salary <= 15600) {
    return 2489;
  }
  if (salary >= 15601 && salary <= 15700) {
    return 2515;
  }
  if (salary >= 15701 && salary <= 15800) {
    return 2540;
  }
  if (salary >= 15801 && salary <= 15900) {
    return 2566;
  }
  if (salary >= 15901 && salary <= 16000) {
    return 2591;
  }
  if (salary >= 16001 && salary <= 16100) {
    return 2617;
  }
  if (salary >= 16101 && salary <= 16200) {
    return 2642;
  }
  if (salary >= 16201 && salary <= 16300) {
    return 2668;
  }
  if (salary >= 16301 && salary <= 16400) {
    return 2694;
  }
  if (salary >= 16401 && salary <= 16500) {
    return 2719;
  }
  if (salary >= 16501 && salary <= 16600) {
    return 2745;
  }
  if (salary >= 16601 && salary <= 16700) {
    return 2770;
  }
  if (salary >= 16701 && salary <= 16800) {
    return 2796;
  }
  if (salary >= 16801 && salary <= 16900) {
    return 2822;
  }
  if (salary >= 16901 && salary <= 17000) {
    return 2847;
  }
  if (salary >= 17001 && salary <= 17100) {
    return 2873;
  }
  if (salary >= 17101 && salary <= 17200) {
    return 2898;
  }
  if (salary >= 17201 && salary <= 17300) {
    return 2924;
  }
  if (salary >= 17301 && salary <= 17400) {
    return 2950;
  }
  if (salary >= 17401 && salary <= 17500) {
    return 2975;
  }
  if (salary >= 17501 && salary <= 17600) {
    return 3001;
  }
  if (salary >= 17601 && salary <= 17700) {
    return 3026;
  }
  if (salary >= 17701 && salary <= 17800) {
    return 3052;
  }
  if (salary >= 17801 && salary <= 17900) {
    return 3078;
  }
  if (salary >= 17901 && salary <= 18000) {
    return 3103;
  }
  if (salary >= 18001 && salary <= 18100) {
    return 3129;
  }
  if (salary >= 18101 && salary <= 18200) {
    return 3154;
  }
  if (salary >= 18201 && salary <= 18300) {
    return 3180;
  }
  if (salary >= 18301 && salary <= 18400) {
    return 3206;
  }
  if (salary >= 18401 && salary <= 18500) {
    return 3231;
  }
  if (salary >= 18501 && salary <= 18600) {
    return 3257;
  }
  if (salary >= 18601 && salary <= 18700) {
    return 3282;
  }
  if (salary >= 18701 && salary <= 18800) {
    return 3308;
  }
  if (salary >= 18801 && salary <= 18900) {
    return 3334;
  }
  if (salary >= 18901 && salary <= 19000) {
    return 3359;
  }
  if (salary >= 19001 && salary <= 19100) {
    return 3385;
  }
  if (salary >= 19101 && salary <= 19200) {
    return 3410;
  }
  if (salary >= 19201 && salary <= 19300) {
    return 3436;
  }
  if (salary >= 19301 && salary <= 19400) {
    return 3462;
  }
  if (salary >= 19401 && salary <= 19500) {
    return 3487;
  }
  if (salary >= 19501 && salary <= 19600) {
    return 3513;
  }
  if (salary >= 19601 && salary <= 19700) {
    return 3538;
  }
  if (salary >= 19701 && salary <= 19800) {
    return 3564;
  }
  if (salary >= 19801 && salary <= 19900) {
    return 3590;
  }
  if (salary >= 19901 && salary <= 20000) {
    return 3615;
  }
  if (salary >= 20001 && salary <= 20200) {
    return 3666;
  }
  if (salary >= 20201 && salary <= 20400) {
    return 3718;
  }
  if (salary >= 20401 && salary <= 20600) {
    return 3769;
  }
  if (salary >= 20601 && salary <= 20800) {
    return 3820;
  }
  if (salary >= 20801 && salary <= 21000) {
    return 3871;
  }
  if (salary >= 21001 && salary <= 21200) {
    return 3922;
  }
  if (salary >= 21201 && salary <= 21400) {
    return 3974;
  }
  if (salary >= 21401 && salary <= 21600) {
    return 4025;
  }
  if (salary >= 21601 && salary <= 21800) {
    return 4076;
  }
  if (salary >= 21801 && salary <= 22000) {
    return 4127;
  }
  if (salary >= 22001 && salary <= 22200) {
    return 4179;
  }
  if (salary >= 22201 && salary <= 22400) {
    return 4232;
  }
  if (salary >= 22401 && salary <= 22600) {
    return 4285;
  }
  if (salary >= 22601 && salary <= 22800) {
    return 4337;
  }
  if (salary >= 22801 && salary <= 23000) {
    return 4390;
  }
  if (salary >= 23001 && salary <= 23200) {
    return 4443;
  }
  if (salary >= 23201 && salary <= 23400) {
    return 4496;
  }
  if (salary >= 23401 && salary <= 23600) {
    return 4549;
  }
  if (salary >= 23601 && salary <= 23800) {
    return 4602;
  }
  if (salary >= 23801 && salary <= 24000) {
    return 4654;
  }
  if (salary >= 24001 && salary <= 24200) {
    return 4707;
  }
  if (salary >= 24201 && salary <= 24400) {
    return 4760;
  }
  if (salary >= 24401 && salary <= 24600) {
    return 4813;
  }
  if (salary >= 24601 && salary <= 24800) {
    return 4866;
  }
  if (salary >= 24801 && salary <= 25000) {
    return 4919;
  }
  if (salary >= 25001 && salary <= 25200) {
    return 4972;
  }
  if (salary >= 25201 && salary <= 25400) {
    return 5024;
  }
  if (salary >= 25401 && salary <= 25600) {
    return 5077;
  }
  if (salary >= 25601 && salary <= 25800) {
    return 5130;
  }
  if (salary >= 25801 && salary <= 26000) {
    return 5183;
  }
  if (salary >= 26001 && salary <= 26200) {
    return 5236;
  }
  if (salary >= 26201 && salary <= 26400) {
    return 5289;
  }
  if (salary >= 26401 && salary <= 26600) {
    return 5342;
  }
  if (salary >= 26601 && salary <= 26800) {
    return 5394;
  }
  if (salary >= 26801 && salary <= 27000) {
    return 5447;
  }
  if (salary >= 27001 && salary <= 27200) {
    return 5500;
  }
  if (salary >= 27201 && salary <= 27400) {
    return 5553;
  }
  if (salary >= 27401 && salary <= 27600) {
    return 5606;
  }
  if (salary >= 27601 && salary <= 27800) {
    return 5659;
  }
  if (salary >= 27801 && salary <= 28000) {
    return 5711;
  }
  if (salary >= 28001 && salary <= 28200) {
    return 5764;
  }
  if (salary >= 28201 && salary <= 28400) {
    return 5817;
  }
  if (salary >= 28401 && salary <= 28600) {
    return 5870;
  }
  if (salary >= 28601 && salary <= 28800) {
    return 5923;
  }
  if (salary >= 28801 && salary <= 29000) {
    return 5976;
  }
  if (salary >= 29001 && salary <= 29200) {
    return 6029;
  }
  if (salary >= 29201 && salary <= 29400) {
    return 6081;
  }
  if (salary >= 29401 && salary <= 29600) {
    return 6134;
  }
  if (salary >= 29601 && salary <= 29800) {
    return 6187;
  }
  if (salary >= 29801 && salary <= 30000) {
    return 6240;
  }
  if (salary >= 30001 && salary <= 30200) {
    return 6293;
  }
  if (salary >= 30201 && salary <= 30400) {
    return 6346;
  }
  if (salary >= 30401 && salary <= 30600) {
    return 6399;
  }
  if (salary >= 30601 && salary <= 30800) {
    return 6451;
  }
  if (salary >= 30801 && salary <= 31000) {
    return 6504;
  }
  if (salary >= 31001 && salary <= 31200) {
    return 6557;
  }
  if (salary >= 31201 && salary <= 31400) {
    return 6610;
  }
  if (salary >= 31401 && salary <= 31600) {
    return 6663;
  }
  if (salary >= 31601 && salary <= 31800) {
    return 6715;
  }
  if (salary >= 31801 && salary <= 32000) {
    return 6768;
  }
  if (salary >= 32001 && salary <= 32200) {
    return 6821;
  }
  if (salary >= 32201 && salary <= 32400) {
    return 6873;
  }
  if (salary >= 32401 && salary <= 32600) {
    return 6928;
  }
  if (salary >= 32601 && salary <= 32800) {
    return 6988;
  }
  if (salary >= 32801 && salary <= 33000) {
    return 7048;
  }
  if (salary >= 33001 && salary <= 33200) {
    return 7108;
  }
  if (salary >= 33201 && salary <= 33400) {
    return 7168;
  }
  if (salary >= 33401 && salary <= 33600) {
    return 7228;
  }
  if (salary >= 33601 && salary <= 33800) {
    return 7288;
  }
  if (salary >= 33801 && salary <= 34000) {
    return 7348;
  }
  if (salary >= 34001 && salary <= 34200) {
    return 7408;
  }
  if (salary >= 34201 && salary <= 34400) {
    return 7468;
  }
  if (salary >= 34401 && salary <= 34600) {
    return 7528;
  }
  if (salary >= 34601 && salary <= 34800) {
    return 7588;
  }
  if (salary >= 34801 && salary <= 35000) {
    return 7648;
  }
  if (salary >= 35001 && salary <= 35200) {
    return 7708;
  }
  if (salary >= 35201 && salary <= 35400) {
    return 7768;
  }
  if (salary >= 35401 && salary <= 35600) {
    return 7828;
  }
  if (salary >= 35601 && salary <= 35800) {
    return 7888;
  }
  if (salary >= 35801 && salary <= 36000) {
    return 7948;
  }
  if (salary >= 36001 && salary <= 36200) {
    return 8008;
  }
  if (salary >= 36201 && salary <= 36400) {
    return 8068;
  }
  if (salary >= 36401 && salary <= 36600) {
    return 8128;
  }
  if (salary >= 36601 && salary <= 36800) {
    return 8188;
  }
  if (salary >= 36801 && salary <= 37000) {
    return 8248;
  }
  if (salary >= 37001 && salary <= 37200) {
    return 8308;
  }
  if (salary >= 37201 && salary <= 37400) {
    return 8368;
  }
  if (salary >= 37401 && salary <= 37600) {
    return 8428;
  }
  if (salary >= 37601 && salary <= 37800) {
    return 8488;
  }
  if (salary >= 37801 && salary <= 38000) {
    return 8548;
  }
  if (salary >= 38001 && salary <= 38200) {
    return 8608;
  }
  if (salary >= 38201 && salary <= 38400) {
    return 8668;
  }
  if (salary >= 38401 && salary <= 38600) {
    return 8728;
  }
  if (salary >= 38601 && salary <= 38800) {
    return 8788;
  }
  if (salary >= 38801 && salary <= 39000) {
    return 8848;
  }
  if (salary >= 39001 && salary <= 39200) {
    return 8908;
  }
  if (salary >= 39201 && salary <= 39400) {
    return 8968;
  }
  if (salary >= 39401 && salary <= 39600) {
    return 9028;
  }
  if (salary >= 39601 && salary <= 39800) {
    return 9088;
  }
  if (salary >= 39801 && salary <= 40000) {
    return 9148;
  }
  if (salary >= 40001 && salary <= 40200) {
    return 9208;
  }
  if (salary >= 40201 && salary <= 40400) {
    return 9268;
  }
  if (salary >= 40401 && salary <= 40600) {
    return 9328;
  }
  if (salary >= 40601 && salary <= 40800) {
    return 9388;
  }
  if (salary >= 40801 && salary <= 41000) {
    return 9448;
  }
  if (salary >= 41001 && salary <= 41200) {
    return 9508;
  }
  if (salary >= 41201 && salary <= 41400) {
    return 9568;
  }
  if (salary >= 41401 && salary <= 41600) {
    return 9628;
  }
  if (salary >= 41601 && salary <= 41800) {
    return 9688;
  }
  if (salary >= 41801 && salary <= 42000) {
    return 9748;
  }
  if (salary >= 42001 && salary <= 42200) {
    return 9808;
  }
  if (salary >= 42201 && salary <= 42400) {
    return 9868;
  }
  if (salary >= 42401 && salary <= 42600) {
    return 9928;
  }
  if (salary >= 42601 && salary <= 42800) {
    return 9988;
  }
  if (salary >= 42801 && salary <= 43000) {
    return 10048;
  }
  if (salary >= 43001 && salary <= 43200) {
    return 10108;
  }
  if (salary >= 43201 && salary <= 43400) {
    return 10168;
  }
  if (salary >= 43401 && salary <= 43600) {
    return 10228;
  }
  if (salary >= 43601 && salary <= 43800) {
    return 10288;
  }
  if (salary >= 43801 && salary <= 44000) {
    return 10348;
  }
  if (salary >= 44001 && salary <= 44200) {
    return 10408;
  }
  if (salary >= 44201 && salary <= 44400) {
    return 10468;
  }
  if (salary >= 44401 && salary <= 44600) {
    return 10528;
  }
  if (salary >= 44601 && salary <= 44800) {
    return 10588;
  }
  if (salary >= 44801 && salary <= 45000) {
    return 10648;
  }
  if (salary >= 45001 && salary <= 45200) {
    return 10708;
  }
  if (salary >= 45201 && salary <= 45400) {
    return 10768;
  }
  if (salary >= 45401 && salary <= 45600) {
    return 10828;
  }
  if (salary >= 45601 && salary <= 45800) {
    return 10888;
  }
  if (salary >= 45801 && salary <= 46000) {
    return 10948;
  }
  if (salary >= 46001 && salary <= 46200) {
    return 11008;
  }
  if (salary >= 46201 && salary <= 46400) {
    return 11100;
  }
  if (salary >= 46401 && salary <= 46600) {
    return 11200;
  }
  if (salary >= 46601 && salary <= 46800) {
    return 11300;
  }
  if (salary >= 46801 && salary <= 47000) {
    return 11400;
  }
  if (salary >= 47001 && salary <= 47200) {
    return 11500;
  }
  if (salary >= 47201 && salary <= 47400) {
    return 11600;
  }
  if (salary >= 47401 && salary <= 47600) {
    return 11700;
  }
  if (salary >= 47601 && salary <= 47800) {
    return 11800;
  }
  if (salary >= 47801 && salary <= 48000) {
    return 11900;
  }
  if (salary >= 48001 && salary <= 48200) {
    return 12000;
  }
  if (salary >= 48201 && salary <= 48400) {
    return 12100;
  }
  if (salary >= 48401 && salary <= 48600) {
    return 12200;
  }
  if (salary >= 48601 && salary <= 48800) {
    return 12300;
  }
  if (salary >= 48801 && salary <= 49000) {
    return 12400;
  }
  if (salary >= 49001 && salary <= 49200) {
    return 12500;
  }
  if (salary >= 49201 && salary <= 49400) {
    return 12600;
  }
  if (salary >= 49401 && salary <= 49600) {
    return 12700;
  }
  if (salary >= 49601 && salary <= 49800) {
    return 12800;
  }
  if (salary >= 49801 && salary <= 50000) {
    return 12900;
  }
  if (salary >= 50001 && salary <= 50200) {
    return 13000;
  }
  if (salary >= 50201 && salary <= 50400) {
    return 13100;
  }
  if (salary >= 50401 && salary <= 50600) {
    return 13200;
  }
  if (salary >= 50601 && salary <= 50800) {
    return 13300;
  }
  if (salary >= 50801 && salary <= 51000) {
    return 13400;
  }
  if (salary >= 51001 && salary <= 51200) {
    return 13500;
  }
  if (salary >= 51201 && salary <= 51400) {
    return 13600;
  }
  if (salary >= 51401 && salary <= 51600) {
    return 13700;
  }
  if (salary >= 51601 && salary <= 51800) {
    return 13800;
  }
  if (salary >= 51801 && salary <= 52000) {
    return 13900;
  }
  if (salary >= 52001 && salary <= 52200) {
    return 14000;
  }
  if (salary >= 52201 && salary <= 52400) {
    return 14100;
  }
  if (salary >= 52401 && salary <= 52600) {
    return 14200;
  }
  if (salary >= 52601 && salary <= 52800) {
    return 14300;
  }
  if (salary >= 52801 && salary <= 53000) {
    return 14400;
  }
  if (salary >= 53001 && salary <= 53200) {
    return 14500;
  }
  if (salary >= 53201 && salary <= 53400) {
    return 14600;
  }
  if (salary >= 53401 && salary <= 53600) {
    return 14700;
  }
  if (salary >= 53601 && salary <= 53800) {
    return 14800;
  }
  if (salary >= 53801 && salary <= 54000) {
    return 14900;
  }
  if (salary >= 54001 && salary <= 54200) {
    return 15000;
  }
  if (salary >= 54201 && salary <= 54400) {
    return 15100;
  }
  if (salary >= 54401 && salary <= 54600) {
    return 15203;
  }
  if (salary >= 54601 && salary <= 54800) {
    return 15309;
  }
  if (salary >= 54801 && salary <= 55000) {
    return 15415;
  }
  if (salary >= 55001 && salary <= 55200) {
    return 15521;
  }
  if (salary >= 55201 && salary <= 55400) {
    return 15627;
  }
  if (salary >= 55401 && salary <= 55600) {
    return 15733;
  }
  if (salary >= 55601 && salary <= 55800) {
    return 15839;
  }
  if (salary >= 55801 && salary <= 56000) {
    return 15945;
  }
  if (salary >= 56001 && salary <= 56200) {
    return 16051;
  }
  if (salary >= 56201 && salary <= 56400) {
    return 16157;
  }
  if (salary >= 56401 && salary <= 56600) {
    return 16263;
  }
  if (salary >= 56601 && salary <= 56800) {
    return 16369;
  }
  if (salary >= 56801 && salary <= 57000) {
    return 16475;
  }
  if (salary >= 57001 && salary <= 57200) {
    return 16581;
  }
  if (salary >= 57201 && salary <= 57400) {
    return 16687;
  }
  if (salary >= 57401 && salary <= 57600) {
    return 16793;
  }
  if (salary >= 57601 && salary <= 57800) {
    return 16899;
  }
  if (salary >= 57801 && salary <= 58000) {
    return 17005;
  }
  if (salary >= 58001 && salary <= 58200) {
    return 17111;
  }
  if (salary >= 58201 && salary <= 58400) {
    return 17217;
  }
  if (salary >= 58401 && salary <= 58600) {
    return 17323;
  }
  if (salary >= 58601 && salary <= 58800) {
    return 17429;
  }
  if (salary >= 58801 && salary <= 59000) {
    return 17535;
  }
  if (salary >= 59001 && salary <= 59200) {
    return 17641;
  }
  if (salary >= 59201 && salary <= 59400) {
    return 17747;
  }
  if (salary >= 59401 && salary <= 59600) {
    return 17853;
  }
  if (salary >= 59601 && salary <= 59800) {
    return 17959;
  }
  if (salary >= 59801 && salary <= 60000) {
    return 18065;
  }
  if (salary >= 60001 && salary <= 60200) {
    return 18171;
  }
  if (salary >= 60201 && salary <= 60400) {
    return 18277;
  }
  if (salary >= 60401 && salary <= 60600) {
    return 18383;
  }
  if (salary >= 60601 && salary <= 60800) {
    return 18489;
  }
  if (salary >= 60801 && salary <= 61000) {
    return 18595;
  }
  if (salary >= 61001 && salary <= 61200) {
    return 18701;
  }
  if (salary >= 61201 && salary <= 61400) {
    return 18807;
  }
  if (salary >= 61401 && salary <= 61600) {
    return 18913;
  }
  if (salary >= 61601 && salary <= 61800) {
    return 19019;
  }
  if (salary >= 61801 && salary <= 62000) {
    return 19125;
  }
  if (salary >= 62001 && salary <= 62200) {
    return 19231;
  }
  if (salary >= 62201 && salary <= 62400) {
    return 19337;
  }
  if (salary >= 62401 && salary <= 62600) {
    return 19443;
  }
  if (salary >= 62601 && salary <= 62800) {
    return 19549;
  }
  if (salary >= 62801 && salary <= 63000) {
    return 19655;
  }
  if (salary >= 63001 && salary <= 63200) {
    return 19761;
  }
  if (salary >= 63201 && salary <= 63400) {
    return 19867;
  }
  if (salary >= 63401 && salary <= 63600) {
    return 19973;
  }
  if (salary >= 63601 && salary <= 63800) {
    return 20079;
  }
  if (salary >= 63801 && salary <= 64000) {
    return 20185;
  }
  if (salary >= 64001 && salary <= 64200) {
    return 20291;
  }
  if (salary >= 64201 && salary <= 64400) {
    return 20397;
  }
  if (salary >= 64401 && salary <= 64600) {
    return 20503;
  }
  if (salary >= 64601 && salary <= 64800) {
    return 20609;
  }
  if (salary >= 64801 && salary <= 65000) {
    return 20715;
  }
  if (salary >= 65001 && salary <= 65200) {
    return 20821;
  }
  if (salary >= 65201 && salary <= 65400) {
    return 20927;
  }
  if (salary >= 65401 && salary <= 65600) {
    return 21033;
  }
  if (salary >= 65601 && salary <= 65800) {
    return 21139;
  }
  if (salary >= 65801 && salary <= 66000) {
    return 21245;
  }
  if (salary >= 66001 && salary <= 66200) {
    return 21351;
  }
  if (salary >= 66201 && salary <= 66400) {
    return 21457;
  }
  if (salary >= 66401 && salary <= 66600) {
    return 21563;
  }
  if (salary >= 66601 && salary <= 66800) {
    return 21669;
  }
  if (salary >= 66801 && salary <= 67000) {
    return 21775;
  }
  if (salary >= 67001 && salary <= 67200) {
    return 21881;
  }
  if (salary >= 67201 && salary <= 67400) {
    return 21987;
  }
  if (salary >= 67401 && salary <= 67600) {
    return 22093;
  }
  if (salary >= 67601 && salary <= 67800) {
    return 22199;
  }
  if (salary >= 67801 && salary <= 68000) {
    return 22305;
  }
  if (salary >= 68001 && salary <= 68200) {
    return 22411;
  }
  if (salary >= 68201 && salary <= 68400) {
    return 22517;
  }
  if (salary >= 68401 && salary <= 68600) {
    return 22623;
  }
  if (salary >= 68601 && salary <= 68800) {
    return 22729;
  }
  if (salary >= 68801 && salary <= 69000) {
    return 22835;
  }
  if (salary >= 69001 && salary <= 69200) {
    return 22941;
  }
  if (salary >= 69201 && salary <= 69400) {
    return 23047;
  }
  if (salary >= 69401 && salary <= 69600) {
    return 23153;
  }
  if (salary >= 69601 && salary <= 69800) {
    return 23259;
  }
  if (salary >= 69801 && salary <= 70000) {
    return 23365;
  }
  if (salary >= 70001 && salary <= 70200) {
    return 23471;
  }
  if (salary >= 70201 && salary <= 70400) {
    return 23577;
  }
  if (salary >= 70401 && salary <= 70600) {
    return 23683;
  }
  if (salary >= 70601 && salary <= 70800) {
    return 23789;
  }
  if (salary >= 70801 && salary <= 71000) {
    return 23895;
  }
  if (salary >= 71001 && salary <= 71200) {
    return 24001;
  }
  if (salary >= 71201 && salary <= 71400) {
    return 24107;
  }
  if (salary >= 71401 && salary <= 71600) {
    return 24213;
  }
  if (salary >= 71601 && salary <= 71800) {
    return 24319;
  }
  if (salary >= 71801 && salary <= 72000) {
    return 24425;
  }
  if (salary >= 72001 && salary <= 72200) {
    return 24531;
  }
  if (salary >= 72201 && salary <= 72400) {
    return 24637;
  }
  if (salary >= 72401 && salary <= 72600) {
    return 24743;
  }
  if (salary >= 72601 && salary <= 72800) {
    return 24849;
  }
  if (salary >= 72801 && salary <= 73000) {
    return 24955;
  }
  if (salary >= 73001 && salary <= 73200) {
    return 25061;
  }
  if (salary >= 73201 && salary <= 73400) {
    return 25167;
  }
  if (salary >= 73401 && salary <= 73600) {
    return 25273;
  }
  if (salary >= 73601 && salary <= 73800) {
    return 25379;
  }
  if (salary >= 73801 && salary <= 74000) {
    return 25485;
  }
  if (salary >= 74001 && salary <= 74200) {
    return 25591;
  }
  if (salary >= 74201 && salary <= 74400) {
    return 25697;
  }
  if (salary >= 74401 && salary <= 74600) {
    return 25803;
  }
  if (salary >= 74601 && salary <= 74800) {
    return 25909;
  }
  if (salary >= 74801 && salary <= 75000) {
    return 26015;
  }
  if (salary >= 75001 && salary <= 75200) {
    return 26121;
  }
  if (salary >= 75201 && salary <= 75400) {
    return 26227;
  }
  if (salary >= 75401 && salary <= 75600) {
    return 26333;
  }
  if (salary >= 75601 && salary <= 75800) {
    return 26439;
  }
  if (salary >= 75801 && salary <= 76000) {
    return 26545;
  }
  if (salary >= 76001 && salary <= 76200) {
    return 26651;
  }
  if (salary >= 76201 && salary <= 76400) {
    return 26757;
  }
  if (salary >= 76401 && salary <= 76600) {
    return 26863;
  }
  if (salary >= 76601 && salary <= 76800) {
    return 26969;
  }
  if (salary >= 76801 && salary <= 77000) {
    return 27075;
  }
  if (salary >= 77001 && salary <= 77200) {
    return 27181;
  }
  if (salary >= 77201 && salary <= 77400) {
    return 27287;
  }
  if (salary >= 77401 && salary <= 77600) {
    return 27393;
  }
  if (salary >= 77601 && salary <= 77800) {
    return 27499;
  }
  if (salary >= 77801 && salary <= 78000) {
    return 27605;
  }
  if (salary >= 78001 && salary <= 78200) {
    return 27711;
  }
  if (salary >= 78201 && salary <= 78400) {
    return 27817;
  }
  if (salary >= 78401 && salary <= 78600) {
    return 27923;
  }
  if (salary >= 78601 && salary <= 78800) {
    return 28029;
  }
  if (salary >= 78801 && salary <= 79000) {
    return 28135;
  }
  if (salary >= 79001 && salary <= 79200) {
    return 28241;
  }
  if (salary >= 79201 && salary <= 79400) {
    return 28347;
  }
  if (salary >= 79401 && salary <= 79600) {
    return 28453;
  }
  if (salary >= 79601 && salary <= 79800) {
    return 28559;
  }
  if (salary >= 79801 && salary <= 80000) {
    return 28665;
  }
  if (salary >= 80001 && salary <= 83200) {
    return salary * .36;
  }
  if (salary >= 83201 && salary <= 83400) {
    return salary * .37;
  }
  if (salary >= 83401 && salary <= 83600) {
    return salary * .37;
  }
  if (salary >= 83601 && salary <= 84000) {
    return salary * .37;
  }
  if (salary >= 84001 && salary <= 84400) {
    return salary * .37;
  }
  if (salary >= 84401 && salary <= 87400) {
    return salary * .37;
  }
  if (salary >= 87401 && salary <= 88600) {
    return salary * .37;
  }
  if (salary >= 88601 && salary <= 90000) {
    return salary * .38;
  }
  if (salary >= 90001 && salary <= 91200) {
    return salary * .38;
  }
  if (salary >= 91201 && salary <= 91400) {
    return salary * .38;
  }
  if (salary >= 91401 && salary <= 92000) {
    return salary * .38;
  }
  if (salary >= 92001 && salary <= 94600) {
    return salary * .38;
  }
  if (salary >= 94601 && salary <= 96200) {
    return salary * .39;
  }
  if (salary >= 96201 && salary <= 97000) {
    return salary * .39;
  }
  if (salary >= 97001 && salary <= 99600) {
    return salary * .39;
  }
  if (salary >= 99601 && salary <= 101200) {
    return salary * .39;
  }
  if (salary >= 101201 && salary <= 101600) {
    return salary * .39;
  }
  if (salary >= 101601 && salary <= 102600) {
    return salary * .40;
  }
  if (salary >= 102601 && salary <= 103400) {
    return salary * .40;
  }
  if (salary >= 103401 && salary <= 108800) {
    return salary * .40;
  }
  if (salary >= 108801 && salary <= 109800) {
    return salary * .40;
  }
  if (salary >= 109801 && salary <= 111800) {
    return salary * .41;
  }
  if (salary >= 111801 && salary <= 113000) {
    return salary * .41;
  }
  if (salary >= 113001 && salary <= 116000) {
    return salary * .41;
  }
  if (salary >= 116001 && salary <= 119400) {
    return salary * .41;
  }
  if (salary >= 119401 && salary <= 121600) {
    return salary * .42;
  }
  if (salary >= 121601 && salary <= 122200) {
    return salary * .42;
  }
  if (salary >= 122201 && salary <= 124000) {
    return salary * .42;
  }
  if (salary >= 124001 && salary <= 128200) {
    return salary * .42;
  }
  if (salary >= 128201 && salary <= 130800) {
    return salary * .42;
  }
  if (salary >= 130801 && salary <= 133400) {
    return salary * .43;
  }
  if (salary >= 133401 && salary <= 137800) {
    return salary * .43;
  }
  if (salary >= 137801 && salary <= 147800) {
    return salary * .43;
  }
  if (salary >= 147801 && salary <= 158200) {
    return salary * .44;
  }
  if (salary >= 158201 && salary <= 174800) {
    return salary * .44;
  }
  if (salary >= 174801 && salary <= 185400) {
    return salary * .45;
  }
  if (salary >= 185401 && salary <= 213600) {
    return salary * .45;
  }
  if (salary >= 213601 && salary <= 224000) {
    return salary * .46;
  }
  if (salary >= 224001 && salary <= 274600) {
    return salary * .46;
  }
  if (salary >= 274601 && salary <= 282800) {
    return salary * .47;
  }
  if (salary >= 282801 && salary <= 383400) {
    return salary * .47;
  }
  if (salary >= 383401 && salary <= 384600) {
    return salary * .47;
  }
  if (salary >= 384601 && salary <= 595600) {
    return salary * .48;
  }
  if (salary >= 595601 && salary <= 641000) {
    return salary * .48;
  }
  if (salary >= 641001 && salary <= 1332200) {
    return salary * .49;
  }
  if (salary >= 1332201 && salary <= 1923400) {
    return salary * .49;
  }
  if (salary >= 1923401) {
    return salary * .5;
  }
  return 0;
};

import React, {useEffect, useState} from 'react';
import './App.css';
import {getUtdelning, getTax, MIN_YEARLY_SALARY_FOR_HUVUDREGELN} from "./skattetabell";

function App() {
  const [fee, setFee] = useState(1000);
  const [salary, setSalary] = useState(51000);
  const [vacation, setVacation] = useState(6);
  const [procent, setProcent] = useState(100);

  const [incomePerMonth, setIncomePerMonth] = useState(0);
  const [incomePerYear, setIncomePerYear] = useState(0);

  const [displayKifPerYearTotal, setDisplayKifPerYearTotal] = useState(0);
  const [displayKifPerYearSalary, setDisplayKifPerYearSalary] = useState(0);
  const [displayKifPerYearAU, setDisplayKifPerYearAU] = useState(0);

  const [displayKifPerMonthTotal, setDisplayKifPerMonthTotal] = useState(0);
  const [displayKifPerMonthSalary, setDisplayKifPerMonthSalary] = useState(0);
  const [displayKifPerMonthAU, setDisplayKifPerMonthAU] = useState(0);

  const ts = (x: any) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  const mr = (x: number): number =>  Math.round(x);

  useEffect(() => {
    const tempIncomePerMonth = fee * 168
    const taxPercentage = 0.206;
    const vacationIncome = vacation * 40 * fee;
    setIncomePerMonth(tempIncomePerMonth);

    const monthlyIncomeAfterSalary = tempIncomePerMonth - (salary * 1.3142);
    const taxableAmount = (monthlyIncomeAfterSalary * 12) - vacationIncome;
    const totalTaxes = taxableAmount * taxPercentage;

    const total = taxableAmount - totalTaxes;
    setIncomePerYear(mr(total - getUtdelning(salary, false, procent)));

    const salaryTaxes = getTax(salary);
    const totalKif = (salary - salaryTaxes) * 12;
    setProcent(100);

    // Set values displayed
    setDisplayKifPerYearTotal(ts(mr(totalKif + (getUtdelning(salary, false, procent) * 0.8))));
    setDisplayKifPerYearSalary(ts(mr(totalKif)));
    setDisplayKifPerYearAU(ts(mr(getUtdelning(salary, true, procent))));

    setDisplayKifPerMonthTotal(ts(mr((totalKif + (getUtdelning(salary, false, procent) * 0.8))/12)));
    setDisplayKifPerMonthSalary(ts(mr(totalKif/12)));
    setDisplayKifPerMonthAU(ts(mr((getUtdelning(salary, true, procent))/12)));
  }, [fee, salary, vacation, procent])

  const showAlert = () => {
    alert('Nedanstående beräkningar baseras på inkomståret 2022 och jag har även antagit att du: \n\r1) Bor i Stockholm \n2) Äger 100% av ditt aktiebolag \n3) Inte har andra anställda än dig själv \n4) Arbetar heltid, d.v.s. 168 timmar per månad \n5) Inte är medlem i kyrkan\n6) Vill göra maximalt med aktieutdelning till 20% skatt varje år');
  };

  return (
    <>
      <header>
        <h2>Konsultkalkylatorn</h2>
        <button onClick={showAlert}>Hur görs beräkningarna?</button>
      </header>
      <div className="container">

        <div className="left-col">

          <div className="form-element">
            <h3>Ditt arvode (kr per timme)</h3>
            <input type="number" min={1} value={fee} onChange={val => setFee(parseInt(val.target.value) || 0)}/>
          </div>

          <div className="form-element">
            <h3>Månadslön (kr, brutto)</h3>
            <input type="number" min={1} value={salary} onChange={val => setSalary(parseInt(val.target.value))}/>
          </div>

          <div className="form-element">
            <h3>Antal veckor semester per år</h3>
            <input type="number" min={0} value={vacation} onChange={val => setVacation(parseInt(val.target.value))}/>
          </div>

        </div>

        <div className="main-col">

          <div className="card">
            <h1>Bolagsintäkter per månad</h1>
            <div className="result">{ts(incomePerMonth)} kr</div>
          </div>

          <div className="card">
            <h1>Maximal aktieutdelning per år till 20% skatt</h1>
            <h3>{(salary * 12) < MIN_YEARLY_SALARY_FOR_HUVUDREGELN ? 'Förenklingsregeln har använts då du inte har tillräckligt hög lön' : 'Huvudregeln har använts då du har tillräckligt hög lön'}</h3>
            <div className="result">{ts(mr(getUtdelning(salary, false, procent)))} kr</div>
          </div>

          <div className="card">
            <h1>Kvar i bolaget per år</h1>
            <h3>Efter bolagsskatt, lön och utdelning</h3>
            <div className="result">{ts(incomePerYear)} kr</div>
          </div>

          <div className="cardHolder">
            <div className="card card-green">
              <h1>Kvar i fickan per år</h1>
              <h3>{displayKifPerYearSalary} kr lön + {displayKifPerYearAU} kr aktieutdelning</h3>
              <div className="result">{ displayKifPerYearTotal} kr</div>
            </div>

            <div className="card card-green">
              <h1>Kvar i fickan per månad</h1>
              <h3>{displayKifPerMonthSalary} kr lön + {displayKifPerMonthAU} kr aktieutdelning</h3>
              <div className="result">{displayKifPerMonthTotal} kr</div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}

export default App;
